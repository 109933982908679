@font-face {
    font-family: 'JosefinSans';
    src: local('JosefinSans'), url(./assets/fonts/JosefinSans.ttf) format('truetype');
}


@font-face {
    font-family: 'FiraSans';
    src: local('FiraSans'), url(./assets/fonts/firaSans/FiraSans-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(./assets/fonts/poppins/Poppins-Regular.ttf) format('truetype');
}


body {
    font-family: 'JosefinSans';
}
